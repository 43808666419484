import React from "react";
import { graphql, Link } from "gatsby";
import PageCopy from "../components/PageCopy";
import styled from "styled-components";

export default function Contact({ data }) {
  const pageCopy = data.pageCopy.contactIntro.split("\n");

  const ContactForm = styled.div`
    width: 100%;
    max-width: 1100px;

    div.formFields {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 5px;
      margin: 0 auto;
    }
    div.formFields label {
      text-align: right;
      margin: 0 auto;
    }
    div.formFields label:after {
      content: ":";
    }
  `;

  return (
    <>
      <PageCopy pageCopy={pageCopy} />
      <ContactForm>
        <form name="contact" method="POST" data-netlify="true">
          <div class="formFields">
            <label for="name">Your Name</label>
            <input type="text" name="name" />
            <label for="email">Your Email</label>
            <input type="email" name="email" />
            <label for="message">Message</label>
            <textarea rows="4" name="message"></textarea>
          </div>
          <button type="submit">Send</button>
        </form>
      </ContactForm>
    </>
  );
}

export const query = graphql`
  query {
    pageCopy: sanityWebsiteCopy {
      contactIntro
    }
  }
`;
